import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Voter from './Voter';
import { LinkContainer } from 'react-router-bootstrap';
import { ref, get, set, serverTimestamp, remove, onValue, off, update } from "firebase/database";

export default function Vote(props) {
	
	const [user] = useAuthState(auth);
	const [isReady, setIsReady] = useState(false);
	const [allowVetoes, setAllowVetoes] = useState(false);
	const [hasVetoed, setHasVetoed] = useState(false);
	const [voteCount, setVoteCount] = useState(0);
	const [restaurantsLoaded, setRestaurantsLoaded] = useState(false);
	const [restaurants, setRestaurants] = useState([]);
	const { number } = useParams();
	const [groupNumber] = useState(number);
	const ts = serverTimestamp();

	useEffect(() => {
		document.title = `Vote | Restaurant Decision System`;
	},[]);

	const setMemberStatus = (status) => {
		const mref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);
		update(mref, {
			status: status
		}).then(() => {
			console.log("Vote: updated member status to " + status);
		});
	}

	const setVetoStatus = (has_vetoed) => {
		console.log("Vote: setting hasVetoed to " + has_vetoed);
		setHasVetoed(has_vetoed);
	}

	const incrementVoteCount = () => {
		console.log("Vote: incrementing vote count");
		setVoteCount(prevVote => prevVote + 1);
	}

	const decrementVoteCount = () => {
		console.log("Vote: decrementing vote count");
		setVoteCount(prevVote => prevVote - 1);
	}

	const updateVote = (place_id, user_id, score) => {
		const vid = user_id + "_" + place_id;
		const vref = ref(db, "groups/" + groupNumber + "/votes/" + vid);
		if ( score ) {
			console.log("Vote: casting vote for user " + user_id + " place " + place_id + " score " + score);
			const v = {
				userId: user_id,
				placeId: place_id,
				score: score,
				timestamp: ts
			}
			get(vref).then((snapshot) => {
				if ( snapshot.size === 0 ) {
					console.log("Vote: this is a new vote, counting");
					incrementVoteCount();
				}
				set(vref, v).then(() => {
					console.log("Vote: saved vote!");
				});
			});
		}
		else {
			console.log("Vote: removing vote for user " + user_id + " place " + place_id);
			remove(vref).then((snapshot) => {
				decrementVoteCount();
			});
		}
	}

	const lis = restaurants.map((r, index) => {
		return (
			<Voter groupNumber={number} setVeto={setVetoStatus} hasVetoed={hasVetoed} allowVetoes={allowVetoes} key={r.place_id} increment={incrementVoteCount} voteChange={updateVote} restaurant={r} />
		)
	});

	const fetchRestaurants = () => {

		if ( !restaurantsLoaded ) {
			console.log("Vote: fetching restaurants");

			const rref = ref(db, "groups/" + groupNumber + "/restaurants");

			get(rref).then((snapshot) => {
				if ( snapshot.size === 0 ) {
					console.log("Vote: no restaurants found");
				}
				snapshot.forEach((r) => {
		
					const index = restaurants.findIndex(re => re.place_id === r.key);

	  			if ( index < 0 ) {
	  				// turn off getting the user names for now
	  //				const uid = r.val().addedby;
	  //				var username = "Guest";

	  //				const uref = ref(db, "users/" + uid);
		//				get(uref).then((ss) => {

		//					username = ss.val().displayName;

							var rs = {
			  				place_id: r.key,
								name: r.val().name,
								address: r.val().address,
								hours: r.val().todaysHours,
			//					addedby: username
							}
							setRestaurants(prev => [...prev, rs]);
							
		//				});
		
					}

				});
				setRestaurantsLoaded(true);
			}).catch((err) => {
				console.log("Vote: could not find restaurants");
			});
		}
	}

	useEffect(() => {

		console.log("Vote: component mounted");

		const gref = ref(db, "groups/" + groupNumber + "/members");

		var loaded = false;

		onValue(gref, (snapshot) => {
			console.log("Vote: members onValue triggered");
			if ( snapshot.size === 0 ) {
				console.log("Vote: group does not exist");
			}
			else {
				var ready = true;
				snapshot.forEach((cs) => {
					const status = cs.val().status;
					console.log("Vote: status of member " + cs.key + ": " + status);
					if ( status !== "selected" && status !== "voting" && status !== "voted" ) {
						ready = false;
					}
				});
				if ( ready && !loaded ) {
					loaded = true;
					console.log("Vote: all ready now!");
					const ggref = ref(db, 'groups/' + groupNumber);
					update(ggref, {
						status: "voting"
					}).then(() => {
						console.log("Vote: updated group status to voting");
						get(ggref).then((snap) => {
							const g = snap.val();
							console.log("Vote: group allows vetoes: " + g.allowVetoes);
							setAllowVetoes(g.allowVetoes);
						});
					});
					fetchRestaurants();
					setMemberStatus("voting");
				}
				setIsReady(ready);
			}
		});

		const cleanUp = () => {
			console.log("Vote: cleaning up")
			try {
				off(gref);
			}
			catch {
				console.log("Vote: could not turn off listener");
			}
		}

		return cleanUp;
		
	},[]);

	return (
		<div className="screen text-center p-4">

			<div className="buttons d-grid gap-2">
				<h3>Vote</h3>

				{
					isReady ? 
					(
						<div className="text-center pb-5">
							<p>Use the slider to vote for each restaurant on a scale of<br /> 1 (hate it) to 10 (love it).</p>
							{ allowVetoes ? <p>You are allowed to veto one restaurant.</p> : '' }
							<ul className="text-start mt-4 list-unstyled">
								{lis}
							</ul>
							{ 
								voteCount >= restaurants.length ?
								<div className="text-start mt-2">
									<LinkContainer to={"/groups/" + groupNumber + "/results"}>
										<Button className="full-width">Save Votes</Button>
									</LinkContainer>
								</div> : <p className="text-center small">Vote for all restaurants to continue.</p>
							}
						</div>
					) : 
					(
						<div className="text-center">
							<Spinner animation="border" className="mb-3" variant="primary" />
							<p>Waiting on everyone to choose their restaurants...</p>
						</div>
					)
				}

			</div>

		</div>
	)
	
}