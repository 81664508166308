import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from 'react-bootstrap/Spinner';
import { auth, db } from './firebase';
import { ref, get } from "firebase/database";
import Header from './Header';
import Dashboard from './Dashboard';
import Landing from './Landing';
import LoginRegister from './LoginRegister';
import CreateGroup from './CreateGroup';
import JoinGroup from './JoinGroup';
import ViewGroup from './ViewGroup';
import Results from './Results';
import RestaurantPicker from './RestaurantPicker';
import { useHistory } from "react-router-dom";
import Vote from './Vote';

export default function App(props) { 

  const [user, loading] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [userLoaded, setUserLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('Guest');
  let history = useHistory();

  // after user logs in, get user info and redirect to dashboard
  const afterLogin = (uid, redirect_to) => {
    if ( !userLoaded && uid ) {
      console.log("App: getting user info after login for user " + uid);
      const uref = ref(db, 'users/' + uid);
      get(uref).then((snapshot) => {
        const data = snapshot.val();
        setDisplayName(data.displayName);
        setIsLoading(false);
        setUserLoaded(true);
        history.push(redirect_to);
      }).catch((err) => {
        console.log("App: could not get user info after login");
      });
    }
    else {
      history.push(redirect_to);
    }
  }

  const afterLogout = () => {
    console.log("App: in afterLogout");
    setIsLoading(false);
    setUserLoaded(false);
    setDisplayName('Guest');
    history.push("/");
  }

  useEffect(() => {
    console.log("App: component mounted");
  }, []);

  useEffect(() => {
    if (loading) {
      console.log("App: loading...");
      setIsLoading(true);
    }
    else {
      // user is logged in and info is not already loaded, load the user's info
      if ( user && !userLoaded ) {
        console.log("App: getting user info for user " + user.uid);
        const uref = ref(db, 'users/' + user.uid);
        get(uref).then((snapshot) => {
          const data = snapshot.val();
          setDisplayName(data.displayName);
          setIsLoading(false);
          setUserLoaded(true);
        }).catch((err) => {
          console.log("App: could not get user info");
          setIsLoading(false);
        });
      }
      else {
        setIsLoading(false);
      }
    }
  }, [loading, user, userLoaded]);

  return (

    <>

      {
        isLoading ? 
        (
          <div className="loading-overlay">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : 
        (
          <div></div>
        )
      }

      {
        user ?
        (
          
            userLoaded ? 
            (
              <HashRouter>
                <Header onlogout={afterLogout} username={displayName} />
                <div className="main-body pt-2">
                  <Switch>
                    <Route path="/groups/create">
                      <CreateGroup />
                    </Route>
                    <Route path="/groups/join">
                      <JoinGroup />
                    </Route>
                    <Route path="/groups/:number/vote">
                      <Vote />
                    </Route>
                    <Route path="/groups/:number/select">
                      <RestaurantPicker />
                    </Route>
                    <Route path="/groups/:number/results">
                      <Results />
                    </Route>
                    <Route path="/groups/:number">
                      <ViewGroup />
                    </Route>
                    <Route path="/dashboard">
                      <Dashboard onlogout={afterLogout} username={displayName} />
                    </Route>
                    <Route path="/">
                      <Dashboard onlogout={afterLogout} username={displayName} />
                    </Route>
                  </Switch>
                </div>
              </HashRouter>
            ) : <div className="loading-overlay"><Spinner animation="border" variant="primary" /></div>
          
        )
     
        :
        <>
       
          <HashRouter>
            <Header onlogout={afterLogout} />
            <div className="main-body">
              <Switch>
                <Route path="/groups/join">
                  <LoginRegister after={afterLogin} />
                </Route>
                <Route path="/login">
                  <LoginRegister after={afterLogin} />
                </Route>
                <Route path="/register">
                  <LoginRegister after={afterLogin} />
                </Route>
                <Route path="/">
                  <Landing />
                </Route>
              </Switch>
            </div>
          </HashRouter>
      
        </>
      }

    </>

  )
  
}