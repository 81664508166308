import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, get, set, onValue, update, off } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from './firebase';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import RestaurantSelect from './RestaurantSelect';
import RestaurantChoice from './RestaurantChoice';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export default function RestaurantPicker(props) {

	let history = useHistory();

	const { number } = useParams();
	const [groupNumber] = useState(number);
	const [groupName, setGroupName] = useState('');
	const [groupPickLimit, setGroupPickLimit] = useState(false);
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const [latitude, setLatitude] = useState(false);
	const [longitude, setLongitude] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	
	const [user] = useAuthState(auth);

	var inits = {
		picker1: false,
		picker2: false,
		picker3: false,
		picker4: false,
		picker5: false,
		picker6: false,
		picker7: false,
		picker8: false
	}

	const [pick1, setPick1] = useState({});
	const [pick2, setPick2] = useState({});
	const [pick3, setPick3] = useState({});
	const [pick4, setPick4] = useState({});
	const [pick5, setPick5] = useState({});
	const [pick6, setPick6] = useState({});
	const [pick7, setPick7] = useState({});
	const [pick8, setPick8] = useState({});

	const [pickerInits, setPickerInits] = useState(inits);

	// monitor when restaurants are picked to see if the user is finished
	useEffect(() => {
		var pick_count = 0;
		if ( pick1 && pick1.name ) { pick_count = pick_count += 1; }
		if ( pick2 && pick2.name ) { pick_count = pick_count += 1; }
		if ( pick3 && pick3.name ) { pick_count = pick_count += 1; }
		if ( pick4 && pick4.name ) { pick_count = pick_count += 1; }
		if ( pick5 && pick5.name ) { pick_count = pick_count += 1; }
		if ( pick6 && pick6.name ) { pick_count = pick_count += 1; }
		if ( pick7 && pick7.name ) { pick_count = pick_count += 1; }
		if ( pick8 && pick8.name ) { pick_count = pick_count += 1; }
		console.log("RestarantPicker: set pick count to " + pick_count);
		if ( pick_count === groupPickLimit ) {
			setAllSelected(true);
		}
		else {
			setAllSelected(false);
		}
	},[pick1,pick2,pick3,pick4,pick5,pick6,pick7,pick8,groupPickLimit]);

	const pickerReady = (index) => {
		const input = document.getElementById("acr-" + index);
		const is_last_picker = parseInt(index) === parseInt(groupPickLimit);
		const dt = new Date();

		if ( input && !pickerInits["picker" + index] ) {
			console.log("RestarantPicker: initializing autocomplete on picker " + index);

			var pi = pickerInits;
			pi["picker" + index] = true;
			setPickerInits(pi);

			const pickerToken = `${user.uid}_${groupNumber}_${index}`;
			console.log("RestarantPicker: session token: " + pickerToken);

		  let autocomplete = new window.google.maps.places.Autocomplete(
		  	input,
		  	{
		  		sessiontoken: pickerToken,
		  		componentRestrictions: { country: "us" },
		  		fields: ["name", "formatted_address", "place_id", "opening_hours", "type"],
		  		location: ( latitude ? `${latitude},${longitude}` : null ),
		  		types: ["establishment"]
		  	}
		  );
		  autocomplete.addListener("place_changed", () => {
		  	console.log("RestarantPicker: place changed for restaurant " + index);
		  	let p = autocomplete.getPlace();

		  	// console.dir(p);

		  	var day = dt.getDay() - 1;
		  	if ( day === -1 ) { day = 6 }

		  	const pic = {
		  		place_id: p.place_id,
		  		address: p.formatted_address,
		  		open_now: (p.opening_hours ? p.opening_hours.open_now : false),
		  		todays_hours: (p.opening_hours && p.opening_hours.weekday_text ? p.opening_hours.weekday_text[day] : ''),
		  		type: (p.types ? p.types.toString() : "restaurant"),
		  		name: p.name
		  	}

		  //	console.dir(pic);

				if ( parseInt(index) === 1 ) { setPick1(pic); }
				else if ( parseInt(index) === 2 ) { setPick2(pic); }
				else if ( parseInt(index) === 3 ) { setPick3(pic); }
				else if ( parseInt(index) === 4 ) { setPick4(pic); }
				else if ( parseInt(index) === 5 ) { setPick5(pic); }
				else if ( parseInt(index) === 6 ) { setPick6(pic); }
				else if ( parseInt(index) === 7 ) { setPick7(pic); }
				else if ( parseInt(index) === 8 ) { setPick8(pic); }

		  });

		  if ( is_last_picker ) { setIsLoading(false); }
		}
		else {
			console.log("RestarantPicker: skipping picker init for picker " + index);
			if ( is_last_picker ) { setIsLoading(false); }
		}
	}

	const saveRest = (index) => {
		console.log("RestarantPicker: saving restaurant " + index);
		const p = (index === 1 ? pick1 : (index === 2 ? pick2 : (index === 3 ? pick3 : (index === 4 ? pick4 : (index === 5 ? pick5 : (index === 6 ? pick6 : (index === 7 ? pick7 : (index === 8 ? pick8 : null))))))));
		const is_last = groupPickLimit === index;
		if ( p && p.place_id ) {
			const pref = ref(db, 'groups/' + groupNumber + '/restaurants/' + p.place_id);
			get(pref).then((snapshot) => {
	  		if ( snapshot.size === 0 ) {
	  			const r = {
	  				name: p.name,
	  				address: p.address,
	  				openNow: p.open_now,
	  				todaysHours: p.todays_hours,
	  				score: 0,
	  				type: p.type,
	  				addedby: user.uid
	  			}
	  			set(pref, r).then(() => {
	  				console.log("RestarantPicker: restaurant " + index + " saved!");
	  				if ( is_last ) {
	  					console.log("RestarantPicker: saving last one...");
	  					setMemberStatus("selected", () => { console.log("RestarantPicker: redirecting to vote"); history.push("/groups/" + groupNumber + "/vote"); } );
	  				}
	  			});
	  		}
	  		else if (is_last) {
	  			console.log("RestarantPicker: saving last one...");
					setMemberStatus("selected", () => { console.log("RestarantPicker: redirecting to vote"); history.push("/groups/" + groupNumber + "/vote"); } );
	  		}
	    }).catch((err) => {
	    	console.log("could not save restaurant " + index);
			});
		}
	}

	const saveRestaurants = () => {
		console.log("RestarantPicker: saving " + groupPickLimit + " restaurants");
		for (let i = 0; i < groupPickLimit; i++) {
		  saveRest(i + 1);
		}
	}

	const setMemberStatus = (status, after) => {
		const mref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);
		update(mref, {
			status: status
		}).then(() => {
			console.log("RestarantPicker: updated member status to " + status);
			if ( after ) { after(); }
		});
	}

	// only called once on component mount 
	useEffect(() => {

		console.log("RestarantPicker: component mounted");
		setMemberStatus("selecting");

		// watch for changes to the group itself
		const nref = ref(db, 'groups/' + groupNumber);
		onValue(nref, (snapshot) => {
			console.log("RestarantPicker: group onValue triggered");
			if ( snapshot.size === 0 ) {
				console.log("RestarantPicker: group does not exist");
			}
			else {
				const data = snapshot.val();
				let s = data.size ? parseInt(data.size) : 3;
				setGroupName(data.name);
				setGroupPickLimit(s);
			}
		});

		let exist = document.getElementById("google-places-api");

		console.log("RestarantPicker: Fetching geo location...");
		axios.get('https://geolocation-db.com/json/').then((r) => {
			if ( r && r.data && r.data.latitude ) {
				setLatitude(r.data.latitude);
				setLongitude(r.data.longitude);
				console.log(`RestarantPicker: Location: ${r.data.latitude}, ${r.data.longitude}`);
				if ( !exist ) {
					console.log("RestarantPicker: loading places script");
				  let script = document.createElement("script");
				  script.type = "text/javascript";
				  script.id = "google-places-api";
				  if (script.readyState) {
				    script.onreadystatechange = function() {
				      if (script.readyState === "loaded" || script.readyState === "complete") {
				        script.onreadystatechange = null;
				        setScriptLoaded(true);
				      }
				    };
				  }
				  else { 
				    script.onload = () => {
				    	setScriptLoaded(true);
				    }
				  }
				  script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDLNL1TjzuSf2G6grqlenWMsn0JeZ9DVUM&libraries=places";
				  document.getElementsByTagName("head")[0].appendChild(script);
				}
				else {
					console.log("RestarantPicker: places script already loaded");
					setScriptLoaded(true);
				}
			}
		});

		const cleanUp = () => {
			console.log("RestarantPicker: cleaning up")
			try {
				off(nref);
			}
			catch {
				console.log("RestarantPicker: could not turn off listener");
			}
		}

		return cleanUp;
		
	}, []);


	return (

		<>
    
	    {
	    	isLoading ? 
		    (
		      <div className="loading-overlay">
		        <Spinner animation="border" variant="primary" />
		      </div>
		    ) : ""
		  }

      <div className="screen p-4">
				<div>
					<h3 className="text-center">Pick Your Restaurants</h3>
					<h5 className="text-center">{groupName}</h5>
					<p className="text-center mb-4">Choose {groupPickLimit} restaurants to add to the voting pool.</p>
					{ 
						scriptLoaded && groupPickLimit ?
						(
							<ul className="list-unstyled restaurant-picker-list">
								{ groupPickLimit > 0 ? ( <RestaurantSelect onready={pickerReady} index="1" />) : "" }
								{ groupPickLimit > 1 ? ( <RestaurantSelect onready={pickerReady} index="2" />) : "" }
								{ groupPickLimit > 2 ? ( <RestaurantSelect onready={pickerReady} index="3" />) : "" }
								{ groupPickLimit > 3 ? ( <RestaurantSelect onready={pickerReady} index="4" />) : "" }
								{ groupPickLimit > 4 ? ( <RestaurantSelect onready={pickerReady} index="5" />) : "" }
								{ groupPickLimit > 5 ? ( <RestaurantSelect onready={pickerReady} index="6" />) : "" }
								{ groupPickLimit > 6 ? ( <RestaurantSelect onready={pickerReady} index="7" />) : "" }
								{ groupPickLimit > 7 ? ( <RestaurantSelect onready={pickerReady} index="8" />) : "" }
							</ul>
						)
						: ""
					}

					<h4 style={{"paddingLeft":"70px"}} className="mt-5 mb-3 text-start">My Choices</h4>

					<ul className="list-unstyled mb-4">
						{ groupPickLimit > 0 ? <RestaurantChoice index="1" restaurant={pick1} /> : "" }
						{ groupPickLimit > 1 ? <RestaurantChoice index="2" restaurant={pick2} /> : "" }
						{ groupPickLimit > 2 ? <RestaurantChoice index="3" restaurant={pick3} /> : "" }
						{ groupPickLimit > 3 ? <RestaurantChoice index="4" restaurant={pick4} /> : "" }
						{ groupPickLimit > 4 ? <RestaurantChoice index="5" restaurant={pick5} /> : "" }
						{ groupPickLimit > 5 ? <RestaurantChoice index="6" restaurant={pick6} /> : "" }
						{ groupPickLimit > 6 ? <RestaurantChoice index="7" restaurant={pick7} /> : "" }
						{ groupPickLimit > 7 ? <RestaurantChoice index="8" restaurant={pick8} /> : "" }
					</ul>

					{ allSelected ? <Button className="mt-4 full-width" onClick={saveRestaurants}>Save Restaurants</Button> : <Alert variant="secondary">Select all {groupPickLimit} restaurants to continue.</Alert> }

				</div>
			</div>
    </>
      
	)
}