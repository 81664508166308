import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { db } from './firebase';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ref, get } from "firebase/database";

export default function Voter(props) {
	
	const [user] = useAuthState(auth);
	const allowVetoes = props.allowVetoes;
	const [hasVoted, setHasVoted] = useState(false);
	const [score, setScore] = useState(5);
	const [restaurant] = useState(props.restaurant);
	const updateVote = props.voteChange;
	const hasVetoedAny = props.hasVetoed;
	const [hasVetoedThis, setHasVetoedThis] = useState(false);

	const setVote = (e) => {
		setScore(e.target.value);
		updateVote(restaurant.place_id, user.uid, e.target.value);
	}

	const veto = (e) => {
		setScore(-999);
		setHasVoted(true);
		setHasVetoedThis(true);
		props.setVeto(true);
		updateVote(restaurant.place_id, user.uid, -999);
	}

	const unveto = (e) => {
		setScore(5);
		setHasVoted(false);
		setHasVetoedThis(false);
		props.setVeto(false);
		updateVote(restaurant.place_id, user.uid, null);
	}

	// load initial vote
	useEffect(() => {
		const vid = user.uid + "_" + restaurant.place_id;
		const vref = ref(db, "groups/" + props.groupNumber + "/votes/" + vid);
		get(vref).then((snapshot) => {
			if ( snapshot.size === 0 ) {
				console.log("Voter: vote does not exist yet");
			}
			else {
				const v = snapshot.val();
				console.log("Vote: loaded vote score: " + v.score);
				if ( v.score === -999 ) { // veto
					setHasVetoedThis(true);
					setScore(v.score);
					setHasVoted(true);
					props.setVeto(true);
					props.increment();
				}
				else {
					setScore(v.score);
					setHasVoted(true);
					props.increment();
				}
			}
		});
	}, []);

	return (
		restaurant ? 
		(
		<li className={"vote-li mb-3 " + (hasVetoedThis ? 'vetoed' : '')}>
			<h6>{restaurant.name}</h6>
			{ restaurant.address && restaurant.address !== '' ? <p>{restaurant.address.replace(", USA","")}</p> : '' }
			{ restaurant.hours && restaurant.hours !== '' ? <p>Hours: {restaurant.hours}</p> : '' }
			{ false ? <p>Picked by: {restaurant.addedby}</p> : '' }
			<Form className="mt-2">
			  <Form.Group>
			    <Form.Range disabled={hasVetoedThis ? 'disabled' : false} name="vote" value={score} onChange={e => { setVote(e); setHasVoted(true); }} min="1" max="10" />
			    <Form.Text className="text-muted">
			    	<p className={"status " + (hasVoted ? 'green' : 'red') }>{hasVoted ? (<><span>✓</span>Your Vote: {hasVetoedThis ? "VETO" : (score + "/10")}</>) : <><span>✕</span> Not Voted</>}
			    		{
			    			allowVetoes ? (
			    				<span className="vt">
			    					{
			    						hasVetoedThis ?
			    						<Button onClick={e => unveto(e)} className="veto" variant="link">Undo</Button> :
			    						(
			    							!hasVetoedAny ?
				    							<>
				    								{ hasVoted ? 'or' : '' }
				    								<Button onClick={e => veto(e)} className="veto" variant="link">Veto</Button>
				    							</> :
				    						(
				    							<span className="al"></span>
				    						)
			    						)
			    					}
			    				</span>
			    			) : ""
			    		}
			    	</p>
			    </Form.Text>
			  </Form.Group>
			</Form>
		</li>
		) : ""
	)

}