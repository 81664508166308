import Alert from 'react-bootstrap/Alert';

export default function RestaurantChoice(props) {

	const restaurant = props.restaurant;
	const index = props.index;
	const is_restaurant = restaurant.type && restaurant.type !== '' && restaurant.type.indexOf('restaurant') >= 0

	return (
		restaurant && restaurant.name ?
		(
			<li className="restaurant-choice d-flex align-items-start mb-4">
				<span className="r-index">{index}</span>
				<div className="r-text">
					<h6>{restaurant.name}</h6>
					{ restaurant.address && restaurant.address !== '' ? <address>{restaurant.address.replace(", USA","")}</address> : '' }
					{ restaurant.todays_hours && restaurant.todays_hours !== '' ? <p className="hours">Hours: {restaurant.todays_hours}</p> : '' }
					{ !restaurant.open_now ? <Alert className="small" variant="secondary">This {is_restaurant ? 'restaurant' : 'place'} may be closed now.</Alert> : '' }
					{ !is_restaurant ? <Alert className="small" variant="secondary">Are you sure this is a restaurant?</Alert> : '' }
				</div>
			</li>
		) : 
		(
			<li className="restaurant-choice d-flex align-items-start mb-4">
				<span className="r-index">{index}</span>
				<div className="r-text">
					<h6>Not selected yet.</h6>
				</div>
			</li>
		)
	)

}