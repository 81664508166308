import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signInWithEmailAndPassword, signInAnonymously, getAuth } from "firebase/auth";
import { getDatabase, ref, set, get, serverTimestamp } from "firebase/database";
import { useLocation } from "react-router-dom";

export default function LoginRegister(props) {

	const googleProvider = new GoogleAuthProvider();
	const facebookProvider = new FacebookAuthProvider();

	const db = getDatabase();
	const ts = serverTimestamp();
	const [redirectUrl, setRedirectUrl] = useState('/#/dashboard');
	const { search } = useLocation();

	const [errorMessage, setErrorMessage] = useState('');

	// Guest Form
	const [validatedGuestForm, setValidatedGuestForm] = useState(false);
	const [showGuestForm, setShowGuestForm] = useState(false);
	const [guestName, setGuestName] = useState('');

	// Register Form
	const [validatedRegisterForm, setValidatedRegisterForm] = useState(false);
	const [showRegisterForm, setShowRegisterForm] = useState(false);
	const [registerName, setRegisterName] = useState('');
	const [registerEmail, setRegisterEmail] = useState('');
	const [registerPassword1, setRegisterPassword1] = useState('');
	const [registerPassword2, setRegisterPassword2] = useState('');
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [passwordValid, setPasswordValid] = useState(true);
	const [registerValid, setRegisterValid] = useState(true);

	// Login Form
	const [validatedLoginForm, setValidatedLoginForm] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [loginEmail, setLoginEmail] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [credentialsValid, setCredentialsValid] = useState(true);
	
	useEffect(() => {
		document.title = `Login or Register | Restaurant Decision System`;

		// see if there's a redirect join code
		const match = search.match(/code=(.*)/);
    var c = match?.[1];
    var code = c && c !== '' ? c : '';
    if ( code && code !== '' ) {
    	setRedirectUrl("/#/groups/join?code=" + code);
    	console.log("LoginRegister: redirect code " + code);
    }

		const cleanUp = () => {
			setGuestName('');
			setShowGuestForm(false);
			setShowRegisterForm(false);
			setShowLoginForm(false);
			setValidatedGuestForm(false);
			setValidatedRegisterForm(false);
			setValidatedLoginForm(false);
			setRegisterName('');
			setRegisterEmail('');
			setRegisterPassword1('');
			setRegisterPassword2('');
			setLoginEmail('');
			setLoginPassword('');
			setPasswordsMatch(true);
			setPasswordValid(true);
			setCredentialsValid(true);
			setRegisterValid(true);
		}
		return cleanUp;
	},[search]);

	// ensure passwords are valid
	useEffect(() => {
		if ( registerPassword2 !== '' && registerPassword1 !== registerPassword2 ) {
			setPasswordsMatch(false);
		}
		else {
			setPasswordsMatch(true);
		}
		if ( registerPassword1 !== '' && registerPassword1.length < 8 ) {
			setPasswordValid(false);
		}
		else {
			setPasswordValid(true);
		}
	},[registerPassword1,registerPassword2]);

	// after user registers, set their display name
	const setUserInfo = () => {
		const us = getAuth().currentUser;
		const name = guestName === '' ? registerName : guestName;
	  if (us && us.uid) {
	    console.log("LoginRegister: setting new user info and redirecting, name: " + name);
	    const uref = ref(db, 'users/' + us.uid);
	    get(uref).then((ss) => {
	    	if ( ss.size === 0 ) {
	    		const nm = us.displayName && us.displayName !== '' ? us.displayName : name;
	    		const u = {
			    	created: ts,
			    	displayName: nm
			    }
			    set(uref, u).then((d) => {
			    	props.after(us.uid, redirectUrl);
			    });
	    	}
	    	else {
	    		console.log("LoginRegister: user already existed, just redirecting");
	    		props.after(us.uid, redirectUrl);
	    	}
	    });
	  }
	}

	const loginGuest = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		setValidatedGuestForm(true);
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
			signInAnonymously(auth).then(() => {
		    console.log("LoginRegister: signed in via guest");
		    setUserInfo();
		  })
		  .catch((error) => {
		  	console.dir(error);
		    console.log("LoginRegister: error with guest login");
		  });
		}
	}

	const loginGoogle = (e) => {
		e.preventDefault();
		signInWithPopup(auth, googleProvider).then((result) => {
      console.log("LoginRegister: signed in via google");
      setUserInfo();
    }).catch((error) => {
    	console.dir(error);
    	if ( error.toString().indexOf("account-exists-with-different-credential") >= 0 ) {
    		setErrorMessage("You already have an account with a different login method. Please use your original method to login.")
    	}
      console.log("LoginRegister: error with google login");
    });
	}

	const loginFacebook = (e) => {
		e.preventDefault();
		signInWithPopup(auth, facebookProvider).then((result) => {
      console.log("LoginRegister: signed in via facebook");
      setUserInfo();
    }).catch((error) => {
    	console.log(error);
    	if ( error.toString().indexOf("account-exists-with-different-credential") >= 0 ) {
    		setErrorMessage("You already have an account with a different login method. Please use your original method to login.")
    	}
      console.log("LoginRegister: error with facebook login");
    });
	}

	const loginWithEmail = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		setValidatedLoginForm(true);
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
    	signInWithEmailAndPassword(auth, loginEmail, loginPassword).then((userCredential) => {
		    console.log("LoginRegister: signed in via email");
		    setUserInfo();
		  }).catch((error) => {
		  	console.dir(error);
		  	setCredentialsValid(false);
		  });
    }
	}

	const register = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		setValidatedRegisterForm(true);
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else if ( registerPassword1 === '' || registerPassword1.length < 8 ) {
    	console.log("LoginRegister: password is invalid!");
    }
    else if ( registerPassword1 !== registerPassword2 ) {
    	console.log("LoginRegister: passwords do not match!");
    }
    else {
    	createUserWithEmailAndPassword(auth, registerEmail, registerPassword1).then((userCredential) => {
		    console.log("LoginRegister: signed in via register");
		    setUserInfo();
		  }).catch((error) => {
		    setRegisterValid(false);
		  });
    }

	}

	return (
		<div className="p-4">
		
			<div className="text-center">
				<h3 className="mb-4">Login or Register</h3>

				<div>
					{ showGuestForm || showRegisterForm || showLoginForm  ? '' : (
							<Button className="full-width mb-3 google-btn" onClick={(e) => loginGoogle(e)}>Login with Google</Button> )
					}
					{ showGuestForm || showRegisterForm || showLoginForm  ? '' : (
							<Button className="full-width mb-3 facebook-btn" onClick={(e) => loginFacebook(e)}>Login with Facebook</Button> )
					}
					{ showGuestForm || showRegisterForm || showLoginForm ? '' : (
						<Button className="full-width mb-4" onClick={(e) => setShowLoginForm(true)}>Login with Email</Button> )
					}
					{
						errorMessage === '' ? '' : (
							<Alert variant="secondary">{ errorMessage }</Alert>
						)
					}
					{ showGuestForm || showRegisterForm || showLoginForm ? '' : (
						<Button variant="link" className="full-width mb-1" onClick={(e) => setShowGuestForm(true)}>Continue as Guest</Button> )
					}
					{ showGuestForm || showRegisterForm || showLoginForm ? '' : (
						<Button className="full-width" variant="link" onClick={(e) => setShowRegisterForm(true)}>Register with Email</Button> )
					}
				</div>

				{
					showGuestForm ? 
					(
						<Form className="text-start" noValidate validated={validatedGuestForm} onSubmit={(e) => loginGuest(e)}>

							<Form.Group className="mb-3" controlId="guest_name">
								<FloatingLabel
							    controlId="guest-name"
							    label="Display Name"
							  >
							    <Form.Control required onChange={e => setGuestName(e.target.value)} value={guestName} type="text" placeholder="Display Name" name="guest_name" />
							    <Form.Control.Feedback type="invalid">
				            Display Name is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

						  <div className="d-flex">
						  	<Button className="me-2" type="submit">Continue as Guest</Button>
						  	<Button onClick={(e) => { setGuestName(''); setValidatedGuestForm(false); setShowGuestForm(false) } } variant="gray">Cancel</Button>
						  </div>

						</Form>

					) : ''
				}

				{
					showLoginForm ? 
					(
						<Form className="text-start" noValidate validated={validatedLoginForm} onSubmit={(e) => loginWithEmail(e)}>

							<Form.Group className="mb-3" controlId="login_email">
								<FloatingLabel
							    controlId="login-email"
							    label="Email Address"
							  >
							    <Form.Control required onChange={e => setLoginEmail(e.target.value)} value={loginEmail} type="email" name="login_email" placeholder="Email Address" />
							    <Form.Control.Feedback type="invalid">
				            Email Address is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

						  <Form.Group className="mb-3" controlId="login_password">
						  	<FloatingLabel
							    controlId="login-password"
							    label="Password"
							  >
							    <Form.Control required onChange={e => setLoginPassword(e.target.value)} value={loginPassword} placeholder="Password" type="password" name="login_password" />
							    <Form.Control.Feedback type="invalid">
				            Password is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

						  { credentialsValid ? '' : <Alert variant="secondary">Please check your email address and password.</Alert> }

						  <div className="d-flex">
						  	<Button variant="primary" className="me-2" type="submit">Login</Button>
						  	<Button onClick={(e) => { setLoginEmail(''); setLoginPassword(''); setValidatedLoginForm(false); setCredentialsValid(true); setShowLoginForm(false) }} variant="gray">Cancel</Button>
						  </div>

						</Form>

					) : ''
				}

				{ 
					showRegisterForm ? 
					(

					<div className="text-start">

						<Form noValidate validated={validatedRegisterForm} onSubmit={(e) => register(e)}>

							<Form.Group className="mb-3" controlId="username">
								<FloatingLabel
							    controlId="register-name"
							    label="Display Name"
							  >
							    <Form.Control required onChange={e => setRegisterName(e.target.value)} value={registerName} type="text" placeholder="Display Name" name="username" />
							    <Form.Control.Feedback type="invalid">
				            Display Name is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

							<Form.Group className="mb-3" controlId="email">
								<FloatingLabel
							    controlId="register-email"
							    label="Email Address"
							  >
							    <Form.Control required onChange={e => setRegisterEmail(e.target.value)} value={registerEmail} type="email" name="email" placeholder="Email Address" />
							    <Form.Control.Feedback type="invalid">
				            Email Address is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

						  <Form.Group className="mb-3" controlId="password">
						  	<FloatingLabel
							    controlId="register-password1"
							    label="Password"
							  >
							    <Form.Control required onChange={e => setRegisterPassword1(e.target.value) } value={registerPassword1} type="password" placeholder="Password" name="password" />
							    <Form.Control.Feedback type="invalid">
				            Password is required.
				          </Form.Control.Feedback>
			         	</FloatingLabel>
						  </Form.Group>

						  { passwordValid ? '' : <Alert variant="secondary">Password needs to be at least 8 characters.</Alert> }

						  <Form.Group className="mb-3" controlId="password2">
						  	<FloatingLabel
							    controlId="register-password2"
							    label="Confirm Password"
							  >
							    <Form.Control required onChange={e => setRegisterPassword2(e.target.value) } value={registerPassword2} placeholder="Confirm Password" type="password" name="password2" />
							    <Form.Control.Feedback type="invalid">
				            Password is required.
				          </Form.Control.Feedback>
				        </FloatingLabel>
						  </Form.Group>

						  { passwordsMatch ? '' : <Alert variant="secondary">Passwords do not match.</Alert> }

						  <div className="d-flex">
						  	<Button variant="primary" className="me-2" type="submit">Register</Button>
						  	<Button onClick={(e) => { setRegisterName(''); setRegisterEmail(''); setRegisterPassword1(''); setRegisterPassword2(''); setValidatedRegisterForm(false); setShowRegisterForm(false); }} variant="gray">Cancel</Button>
						  </div>

						  { registerValid ? '' : <Alert className="m2-3" variant="secondary">That email address is already in use. Please login instead.</Alert> }

						</Form>

					</div>
				) : 
					('')
				}
	

			</div>
	
		</div>
	);

}