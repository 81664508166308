import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import icon from '../images/restaurant.png';
import { auth } from './firebase';
import { signOut } from "firebase/auth";

export default function Dashboard(props) {

	const [displayName] = useState(props.username);

	useEffect(() => {
		document.title = `Dashboard | Restaurant Decision System`;
	},[]);

	const logout = () => signOut(auth).then(() => {
    console.log("signed out");
    props.onlogout();
  }).catch((error) => {
    console.log("could not sign out");
  });

	return (
		<div className="screen text-center p-4">

			<img
				src={icon}
				width={80}
				alt="Restaurant Decision System"
				className="d-inline-block mb-4"
			/>

			<h3>Welcome to RDS</h3>

			<h5 className="mb-4">Hello, {displayName}</h5>

			<div className="buttons d-grid gap-2">
				<LinkContainer to="/groups/create">
					 <Button variant="primary">Create a Group</Button>
				</LinkContainer>
				<LinkContainer to="/groups/join">
					 <Button variant="secondary">Join a Group</Button>
				</LinkContainer>
				<Button onClick={logout} variant="dark">Logout</Button>
			</div>
		</div>
	)
	
}