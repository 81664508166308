import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDBSXYuJxFU2D7SlyAYe_-erQBUphBGYBs",
  authDomain: "dine-dyno.firebaseapp.com",
  projectId: "dine-dyno",
  storageBucket: "dine-dyno.appspot.com",
  messagingSenderId: "830774334638",
  appId: "1:830774334638:web:d3023c5837898cc9a7c93f",
  measurementId: "G-DCXRLC65TB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase(app);

export {
  auth,
  db
};