import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { ref, get, set, serverTimestamp } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from './firebase';

export default function JoinGroup(props) {

	const { search } = useLocation();
  const match = search.match(/code=(.*)/);
  var c = match?.[1];
  var code = c && c !== '' ? c : '';

	const [validated, setValidated] = useState(false);
	const [groupNumber, setGroupNumber] = useState(code);
	const [codeValid, setCodeValid] = useState(true);
	const [user] = useAuthState(auth);
	const ts = serverTimestamp();

	let history = useHistory();

	useEffect(() => {
		document.title = 'Join a Group | Restaurant Decision System';

		console.log("JoinGroup: found URL code: " + code);
		if ( code && code !== '' ) {
			joinGroup(null);
		}

	},[]);

	const joinGroup = (e) => {

		if (e) { e.preventDefault(); }

		console.log("JoinGroup: trying to join group");

		const form = e ? e.currentTarget : null;

		setValidated(true);
		setCodeValid(true);

    if (form && form.checkValidity() === false) {
      e.stopPropagation();
    }

    else {

			console.log("JoinGroup: joining group: " + groupNumber );

			const nref = ref(db, 'groups/' + groupNumber);

			get(nref).then((snapshot) => {

				if ( snapshot.size === 0 ) {
					console.log("JoinGroup: group does not exist");
					setCodeValid(false);
				}

				else {

					const mref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);
					const path = ("/groups/" + groupNumber);

					const cr = snapshot.val().creator;
					const memberstatus = user.uid === cr ? "creator" : "member";

					get(mref).then((ss) => {
						if (ss.size === 0) {
							set(mref, {
								role: memberstatus,
								status: "lobby",
								joined: ts
							}).then(() => {
								console.log("JoinGroup: joined group " + groupNumber);
								history.push(path);
							})
						}
						else {
							console.log("JoinGroup: already in this group");
							history.push(path);
						}
					}).catch((err) => {
						console.log("JoinGroup: could not get group member info");
					});

				}
			}).catch((error) => {
				console.error(error);
			});

		}
	}

	return (
		<div className="screen p-4">

			<div>
				<h3 className="text-center mb-4">Join a Group</h3>

				<Form noValidate validated={validated} onSubmit={(e) => joinGroup(e)}>
					<Form.Group className="mb-4" controlId="group-code">
				    <FloatingLabel
					    controlId="group-code"
					    label="Group Code"
					  >
					    <Form.Control required onChange={e => setGroupNumber(e.target.value)} value={groupNumber} maxLength="6" type="text" placeholder="Group Code" />
					    <Form.Text className="text-muted">
					      Enter the code for this group, like 123456
					    </Form.Text>
					    <Form.Control.Feedback type="invalid">
			          Group Code is required.
			        </Form.Control.Feedback>
			      </FloatingLabel>
				  </Form.Group>

				  <div className="d-flex">
					  <Button variant="primary" className="me-2" type="submit">
					    Join Group
					  </Button>
					  <LinkContainer to="/dashboard">
				  		<Button variant="gray">Cancel</Button>
				  	</LinkContainer>
				  </div>

				  { codeValid ? '' : <Alert className="mt-4" variant="secondary">Sorry, that Group Code is invalid.</Alert> }

				</Form>
			</div>

		</div>
	)
	
}