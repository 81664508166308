import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';

export default function RestaurantSelect(props) {

	const [index] = useState(props.index);

	let ready = props.onready;

	useEffect(() => {
		ready(index);
	},[ready,index]);

	const search = (e) => {
		e.preventDefault();
	}

	return (
		<div className="mb-4">
			<h5>Restaurant {props.index}</h5>
		
			<div className="text-start">

				<Form noValidate onSubmit={(e) => search(e)}>

					<Form.Group className="mb-2">
				    <Form.Control id={"acr-" + props.index} type="text" name="restaurant_name" />
				  </Form.Group>

				</Form>

				
			</div>
	
		</div>
	)

}