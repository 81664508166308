import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import icon from '../images/restaurant.png';

export default function Landing(props) {
	
	useEffect(() => {
		document.title = `Restaurant Decision System`;
	},[]);

	return (
		<div className="screen text-center p-4">

			<img
				src={icon}
				width={80}
				alt="Restaurant Decision System"
				className="d-inline-block mb-4"
			/>

			<h3 className="mb-3">Welcome</h3>
			<p>Restaurant Decision System is a social app that helps your group decide where to eat.</p>
			<LinkContainer to="/login">
				<Button className="mt-3">Login or Register</Button>
			</LinkContainer>
		</div>
	)
	
}