import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue, get, remove, onDisconnect, update, off, set, serverTimestamp } from "firebase/database";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { auth } from './firebase';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from 'react-router-dom';

export default function ViewGroup(props) {

	const { number } = useParams();
	const ts = serverTimestamp();
	const [groupNumber] = useState(number);
	const [groupName, setGroupName] = useState('');
	const [groupStatus, setGroupStatus] = useState('');
	const [groupCreator, setGroupCreator] = useState('');
	const [groupMembers, setGroupMembers] = useState([]);
	const [user] = useAuthState(auth);

	const groupUrl = "https://rds.billyswift.com/#/groups/join?code=" + groupNumber;

	let db = getDatabase();
	let history = useHistory();

	useEffect(() => {
		document.title = `${groupName} | Restaurant Decision System`;
	},[groupName]);

	const lis = groupMembers.map((m, index) => {
		return (
			<li key={m.uid}>
				{m.displayName}
			</li>
		)
	});

	const begin = () => {
		const gref = ref(db, "/groups/" + groupNumber);
		update(gref, {
			status: "selecting"
		}).then(() => {
			history.push("/groups/" + groupNumber + "/select");
		}).catch((error) => {

		});
	}

	const shareLink = () => {
		navigator.share({
      title: `${groupName}`,
      url: groupUrl
    }).then(() => {
      console.log('Thanks for sharing!');
    })
    .catch(console.error);
	}

	useEffect(() => {
		console.log("ViewGroup: groupMembers changed");
	//	console.dir(groupMembers);
	},[groupMembers]);

	// only called once on component mount or when user changes
	useEffect(() => {

		console.log("ViewGroup: component mounted");

		// group reference
		const nref = ref(db, 'groups/' + groupNumber);

		// this member reference
		const memref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);

		// all members reference
		const mref = ref(db, 'groups/' + groupNumber + '/members');

		// make sure this user is a member of the group
		get(memref).then((ss) => {
			if (ss.size === 0) {
				console.log("ViewGroup: user is not a member in this group yet, adding...");

				get(nref).then((s3) => {
					const cr = s3.val().creator;
					console.log("ViewGroup: group creator is " + cr);
					const memberstatus = user.uid === cr ? "creator" : "member";
					set(memref, {
						role: memberstatus,
						status: "lobby",
						joined: ts
					}).then(() => {
						console.log("ViewGroup: user " + user.uid + " joined group " + groupNumber + " as " + memberstatus);
					});

				});
				
			}
			else {
				console.log("ViewGroup: already in this group");
			}
		}).catch((err) => {
			console.log("ViewGroup: could not get group member info");
		});

		// watch for changes to the group itself
		onValue(nref, (snapshot) => {
			console.log("ViewGroup: group onValue triggered");
			if ( snapshot.size === 0 ) {
				console.log("ViewGroup: group does not exist");
			}
			else {
				const data = snapshot.val();
				setGroupName(data.name);
				setGroupCreator(data.creator);
				setGroupStatus(data.status);
				console.log("ViewGroup: group status: " + data.status);
				if ( data.status === "selecting" && user.uid !== data.creator ) {
					history.push("/groups/" + groupNumber + "/select");
				}
			}
		});

		// watch for changes to group members
		onValue(mref, (snapshot) => {
			console.log("ViewGroup: members onValue triggered");
			console.log("ViewGroup: members snapshot size: " + snapshot.size);
			if ( snapshot.size === 0 ) {
				console.log("ViewGroup: group does not exist");
			}
			else {
				var memberArray = [];
				var memberIndex = 1;
				snapshot.forEach((m) => {
					console.log("ViewGroup: getting user info for member " + m.key);
					const uref = ref(db, 'users/' + m.key);
					get(uref).then((ss) => {
			  		const data = ss.val();
		  			var mem = {
							uid: m.key,
							displayName: data.displayName
						}
						memberArray.push(mem);
						if ( memberIndex === snapshot.size ) {
							console.log("ViewGroup: last group member, setting groupMembers");
							setGroupMembers(memberArray);
						}
						memberIndex += 1;
			    }).catch((err) => {
			    	console.log("could not get user info"); 
					});
				});
			}
		});

		// watch for this member disconnecting
		onDisconnect(memref).remove();

		const cleanUp = () => {
			console.log("ViewGroup: cleaning up");
			try {
				get(nref).then((ss) => {
					var st = ss.val().status;
					console.log(`ViewGroup: group status is ${st}`);
					if ( st === 'lobby' ) {
						console.log("ViewGroup: user left the lobby, removing member");
						remove(memref).then((s) => {
							console.log("ViewGroup: member removed from group");
						});
					}
				});
				off(mref);
				off(nref);
				off(memref);
			}
			catch {
				console.log("ViewGroup: could not turn off listeners");
			}
		}

		return cleanUp;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (

		<div className="screen text-center p-4">

			<div>
				<h3>{groupName}</h3>

				<h6 className="mb-4">Group Code: {groupNumber}</h6>

				{
					groupStatus === 'lobby' ? 
					(
						<div>
							<h6>Invite Link:</h6>

							<Form>
								<Form.Group className="mb-4" controlId="group-code">
							    <Form.Control readOnly value={groupUrl} type="text" />
							    <Form.Text className="text-muted">
							      Share this link with your dining party.
							    </Form.Text>
							  </Form.Group>
							</Form>

							{ navigator.share ? <Button onClick={shareLink}>Share</Button> : "" }

							<h5 className="mt-4">Members</h5>

							<ul className="list-unstyled">
								{lis}
							</ul>

							<div className="text-center">
								<Spinner animation="border" variant="primary" />
								{ user.uid === groupCreator ? <p>Waiting for people to join...</p> : <p>Waiting for the host to start...</p> }
							</div>

							{ user.uid === groupCreator && groupMembers.length > 1 ?
								(
									<Button className="full-width" onClick={begin}>Start</Button>
								) : '' }
						</div>
					) : 
					(
						<div>
							<p>This group has expired.</p>

							<LinkContainer to="/dashboard">
								<Button className="full-width mt-4">Start Over</Button>
							</LinkContainer>
						</div>
					)
				}

			</div>

		</div>

	)

}