import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { auth, db } from './firebase';
import { LinkContainer } from 'react-router-bootstrap';
import { ref, set, get } from "firebase/database";
import { useHistory } from 'react-router-dom';
import { serverTimestamp } from 'firebase/database';
import { useAuthState } from "react-firebase-hooks/auth";

export default function CreateGroup(props) {

	const [name, setName] = useState('');
	const [size, setSize] = useState(5);
	const [validated, setValidated] = useState(false);
	const [allowVetoes, setAllowVetoes] = useState(false);
	const [user] = useAuthState(auth);

	let history = useHistory();

	useEffect(() => {
		document.title = 'Create a Group | Restaurant Decision System';
	},[]);

	const createGroup = (e) => {

		e.preventDefault();

		const form = e.currentTarget;

		setValidated(true);

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    else {

			console.log("CreateGroup: creating group: " + name);

			const ts = serverTimestamp();
		
			const group = {
				creator: user.uid,
				status: "lobby",
				size: size,
				name: name,
				allowVetoes: allowVetoes,
				created: ts,
				members: []
			}

			var groupNumber = (Math.floor(100000 + Math.random() * 900000)).toString();

			console.log("CreateGroup: trying group number: " + groupNumber);

			const nref = ref(db, 'groups/' + groupNumber);

			get(nref).then((snapshot) => {
				
				if ( snapshot.size === 0 ) {
					
					set(nref, group).then(() => {
						console.log("CreateGroup: created group " + groupNumber);

						const mref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);

						set(mref, {
							role: "creator",
							status: "lobby",
							joined: ts
						}).then(() => {
							console.log("CreateGroup: added creator member to group " + groupNumber);
							const path = ("/groups/" + groupNumber);
							history.push(path);
						});

					});

				}
				else {
					console.log("CreateGroup: found duplicate group number, skipping");
					createGroup(e);
				}
			}).catch((error) => {
				console.error(error);
			});

		}

	}

	return (
		<div className="screen p-4">

			<div>

				<h3 className="text-center mb-3">Create a Group</h3>

				<Form noValidate validated={validated} onSubmit={(e) => createGroup(e)}>

					<Form.Group className="mb-4" controlId="group-name">
						<FloatingLabel
					    controlId="group-name"
					    label="Group Name"
					  >
					    <Form.Control required onChange={e => setName(e.target.value)} value={name} type="text" name="name" placeholder="Group Name" />
					    <Form.Text className="text-muted">
					      Example: "Dinner Tonight"
					    </Form.Text>
					    <Form.Control.Feedback type="invalid">
		            Group Name is required.
		          </Form.Control.Feedback>
					  </FloatingLabel>
				  </Form.Group>

				  <Form.Group className="mb-4" controlId="group-size">
				    <Form.Label>Restaurant Choices Per Person</Form.Label>
				    <Form.Range onChange={e => setSize(e.target.value)} name="size" value={size} min="2" max="8" />
				    <Form.Text className="text-muted">
				      Each person gets {size} choices.
				    </Form.Text>
				  </Form.Group>

				  <Form.Group className="mb-4" controlId="group-size">
				  	<Form.Label>Allow Vetoes</Form.Label>
				  	<div className="mb-1">
					  	<ButtonGroup aria-label="Allow Vetoes">
							  <Button onClick={e => setAllowVetoes(true)} className={allowVetoes ? '' : 'faded'} variant="primary">Yes</Button>
							  <Button onClick={e => setAllowVetoes(false)} className={allowVetoes ? 'faded' : ''} variant="primary">No</Button>
							</ButtonGroup>
						</div>
				  	<Form.Text className="text-muted">
				      Each person gets one restaurant veto.
				    </Form.Text>
				  </Form.Group>

				  <div className="d-flex">
				  	<Button variant="primary" className="me-2" type="submit">
					    Create Group
					  </Button>
					  <LinkContainer to="/dashboard">
				  		<Button variant="gray">Cancel</Button>
				  	</LinkContainer>
				  </div>

				</Form>
			</div>
	
		</div>
	)

}