import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { ref, onValue, off, get, update } from "firebase/database";
import { LinkContainer } from 'react-router-bootstrap';
import plate from '../images/plate.png';

export default function Results(props) {
	
	const [user] = useAuthState(auth);
	const [isReady, setIsReady] = useState(false);
	const [winnerName, setWinnerName] = useState('');
	const [winnerAddress, setWinnerAddress] = useState('');
	const [winnerHours, setWinnerHours] = useState('');
	const [winnerContributor, setWinnerContributor] = useState('');

	const { number } = useParams();
	const [groupNumber] = useState(number);

	useEffect(() => {
		document.title = `Results | Restaurant Decision System`;
	},[]);

	const setMemberStatus = (status) => {
		const mref = ref(db, 'groups/' + groupNumber + '/members/' + user.uid);
		update(mref, {
			status: status
		}).then(() => {
			console.log("Results: updated member status to " + status);
		});
	}

	const getWinnerContributor = (uid) => {
		console.log("Results: getting winner contributor display name...");
		const uref = ref(db, "users/" + uid);
		get(uref).then((snapshot) => {
			var us = snapshot.val();
			setWinnerContributor(us.displayName);
		});
	}

	const calculateWinner = () => {
		console.log("Results: calculating winner...");
		var tally = {};
		const vref = ref(db, "groups/" + groupNumber + "/votes");
		get(vref).then((snapshot) => { 
			snapshot.forEach((vote) => {
				const v = vote.val();
				if ( tally[v.placeId] ) {
					const old = tally[v.placeId];
					tally[v.placeId] = (parseInt(v.score) + old);
				}
				else {
					tally[v.placeId] = parseInt(v.score);
				}
			});
			const arr = Object.entries(tally);
			const sorted = arr.sort((a, b) => (a[1] - b[1]));
			const winner_place_id = sorted[sorted.length - 1][0];
			console.log("Results: calculated winner to be " + winner_place_id);
			const gref = ref(db, 'groups/' + groupNumber);
			update(gref, {
				winner: winner_place_id,
				status: "complete"
			}).then(() => {
				console.log("Results: updated group winner to " + winner_place_id);
			});
			const wref = ref(db, "groups/" + groupNumber + "/restaurants/" + winner_place_id);
			get(wref).then((snapshot) => {
				var re = snapshot.val();
				console.log("Results: set winner name to " + re.name);
				setWinnerName(re.name);
				setWinnerAddress(re.address);
				setWinnerHours(re.todaysHours);
				getWinnerContributor(re.addedby);
			});
		});
	}

	useEffect(() => {

		console.log("Results: component mounted");

		setMemberStatus("voted");

		const gref = ref(db, "groups/" + groupNumber + "/members");

		console.log("Results: getting group info");
		const tref = ref(db, "groups/" + groupNumber);

		get(tref).then((snapshot) => {
			if ( snapshot.size === 0 ) {
				console.log("group does not exist");
			}
			else {
				const data = snapshot.val();
			//	setGroupCreator(data.creator);

				onValue(tref, (s2) => {
					const da = s2.val();
					console.log("Results: group onValue triggered");
					if ( s2.size === 0 ) {
						console.log("Results: group does not exist");
					}
					else {
						if ( da.winner ) {
							console.log("Results: group already has a winner, setting winner name");
							const wref = ref(db, "groups/" + groupNumber + "/restaurants/" + da.winner);
							get(wref).then((s1) => {
								var re = s1.val();
								setWinnerName(re.name);	
								setWinnerAddress(re.address);
								setWinnerHours(re.todaysHours);
								getWinnerContributor(re.addedby);
								console.log("Results: set winner name to " + re.name);
							});
						}
					}
				});

				onValue(gref, (ss) => {
					console.log("Results: members onValue triggered");

					if ( ss.size === 0 ) {
						console.log("Results: group does not exist");
					}
					else {
						console.log("Results: group doesn't have a winner yet, waiting for everyone to vote");
						var ready = true;
						ss.forEach((cs) => {
							const status = cs.val().status;
							console.log("Results: status of member " + cs.key + ": " + status);
							if ( status !== "voted" ) {
								ready = false;
							}
						});

						// creator calculates results
						if ( ready && data.creator === user.uid && winnerName === '' ) {
							calculateWinner();
							console.log("Results: all ready now!");
						}

						setIsReady(ready);

					}
				});

			}
		});

		const cleanUp = () => {
			console.log("Results: cleaning up")
			try {
				off(gref);
				off(tref);
			}
			catch {
				console.log("Results: could not turn off listener");
			}
		}

		return cleanUp;
		
	},[]);

	return (

		<div className="screen text-center p-4">
			{
				isReady ? 
				(
					<div className="text-center pb-5">
						{
							winnerName === '' ? 
							(
								<div>
									<h3 className="mb-1">Results</h3>
									<p>Let's see who won!</p>
									<Spinner animation="border" variant="primary" />
									<p>Calculating...</p>
								</div>
							) :
							(
								<div className="group-results">
									<img
										src={plate}
										width={80}
										alt="Restaurant Decision System"
										className="d-inline-block"
									/>
									<h5 className="mt-4">The winner is...</h5>
									<h2 className="mb-3">{winnerName}!</h2>
									{ winnerHours !== '' ? <p>Hours: {winnerHours}</p> : '' }
									{ winnerContributor !== '' ? <p>Picked by: {winnerContributor}</p> : '' }
									{ winnerAddress !== '' ? (
										<a target="_blank" rel="noreferrer" className="btn full-width mt-4 btn-secondary" href={"https://www.google.com/maps/dir//" + winnerAddress}>Get Directions</a>
										) : "" }

									<LinkContainer to="/dashboard">
										<Button variant="primary" className="full-width mt-2">Start Over</Button>
									</LinkContainer>
								</div>
							)
						}
					</div>
				) : 
				(
					<div className="text-center">
						<h3 className="mb-1">Results</h3>
						<p>Let's see who won!</p>
						<Spinner animation="border" variant="primary" />
						<p>Waiting on everyone to finish voting...</p>
					</div>
				)
			}
		</div>
	)

}