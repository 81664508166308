import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from './firebase';
import { signOut } from "firebase/auth";
import icon from '../images/restaurant.png';

export default function Header(props) {

	const [user] = useAuthState(auth);
	const [displayName] = useState(props.username);

	const logout = () => signOut(auth).then(() => {
    console.log("signed out");
    props.onlogout();
  }).catch((error) => {
    console.log("could not sign out");
  });

	return (

		<div className="header-wrap">

			<header className="main text-center py-3">

				<div className="d-flex justify-content-center align-items-center">

					<Link className="d-block logo-link me-2" to={user ? "/dashboard" : "/"}>
						<img
							src={icon}
							height={40}
							alt="Restaurant Decision System"
							className="d-block"
						/>
					</Link>

					<Link className="d-block logo-link" to={user ? "/dashboard" : "/"}>
						<h5>Restaurant Decision System</h5>
					</Link>

					<div className="user-info">
						{ false ? 
							(
								<div>
									<p>Hi, {displayName && displayName !== '' ? displayName : 'Guest'}</p>
									<Link to="/" onClick={logout}>Logout</Link>
								</div>
							)
							:
							( 
								( false ?
								<div className="d-flex d-grid gap-2 justify-content-center">
									<Button size="sm" className="me-2" to="/login">Login</Button>
								</div> : "" )
							)
						}
					</div>
				</div>

			</header>
		</div>
	)
	
}